import React from 'react';
import { BackButton } from 'components/back-button';
import { ContentWrapper } from 'components/content-wrapper';
import { PATHS } from 'constants/paths';
import { node } from 'prop-types';

import { BannersSection } from './BannersSection';

export const Content = ({ children }) => (
  <ContentWrapper>
    {children}
    <BannersSection />
    <BackButton to={PATHS.OFFER} />
  </ContentWrapper>
);

Content.propTypes = {
  children: node.isRequired,
};
