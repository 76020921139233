import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';

const SWrapper = styled.div`
  margin-bottom: 45px;
`;

export const Section = ({ children }) => <SWrapper>{children}</SWrapper>;

Section.propTypes = {
  children: node.isRequired,
};
