import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import MEDIA from 'styles/media';

const SContentWrapper = styled.div`
  width: 70%;
  margin: 0 auto;

  ${MEDIA.max1024`
    width: 90%;
  `};
`;

export const ContentWrapper = ({ children }) => {
  return <SContentWrapper>{children}</SContentWrapper>;
};

ContentWrapper.propTypes = {
  children: node.isRequired,
};
