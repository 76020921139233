import React from 'react';
import { OtherTreatments } from 'components/other-treatments';
import { PageWrapper } from 'components/page-wrapper';
import { OFFERS } from 'constants/offers';
import { array, node } from 'prop-types';

export const Offer = ({ children, otherTreatments }) => (
  <PageWrapper>
    {children}
    {otherTreatments.length > 0 && (
      <OtherTreatments offers={otherTreatments} title="Inne zabiegi" />
    )}
  </PageWrapper>
);

Offer.propTypes = {
  children: node.isRequired,
  otherTreatments: array,
};

Offer.defaultProps = {
  otherTreatments: OFFERS.slice(0, 4),
};
