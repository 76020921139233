import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

// Assuming each child is wrapped in an element (e.g., a div) that you control the width of
const SImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 0 0 100%; /* Full width by default */
  box-sizing: border-box;
  padding: 20px; /* Adjust padding as needed */

  @media (min-width: 768px) {
    /* Adjust breakpoint as needed */
    flex: 0 0 50%; /* Two columns on desktop */
  }
`;

export const ImageWrapper = ({ children, className }) => (
  <SImageWrapper className={className}>
    {React.Children.map(children, (child) => (
      <ImageContainer>{child}</ImageContainer>
    ))}
  </SImageWrapper>
);

ImageWrapper.defaultProps = {
  className: '',
};

ImageWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};
