import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer5 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "ig-usta-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      second: file(relativePath: { eq: "ig-usta-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      third: file(relativePath: { eq: "ig-usta-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "ig-usta-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      sixth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.modelowanieUst}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Powiększanie lub modelowanie ust kwasem hialuronowym
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.sixth.childImageSharp}
                alt="modelowanie powiększenie ust kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania do zabiegu modelowania ust</Subtitle>
            <Paragraph>
              Powiększanie lub modelowanie ust kwasem hialuronowym jest to
              obecnie jeden z najpopularniejszych zabiegów medycyny estetycznej.
              Wynika to z faktu, że umiejętnie przeprowadzony wyraźnie poprawia
              atrakcyjność twarzy, a jest stosunkowo mało nieinwazyjny.
            </Paragraph>
            <Paragraph noMarginBottom>
              Podanie kwasu hialuronowego w obrębie ust może mieć kilka
              zastosowań:
            </Paragraph>
            <List>
              <ListItem>Zwiększenie objętości ust</ListItem>
              <ListItem>Wymodelowanie kształtu ust</ListItem>
              <ListItem>Wyrównanie konturu i regeneracja ust</ListItem>
              <ListItem>Nawilżanie ust</ListItem>
              <ListItem>Wypełnienie “zmarszczek palacza” wokół ust</ListItem>
              <ListItem>Podniesienie kącików ust</ListItem>
              <ListItem>
                Korekta słabo wykształconej wargi górnej lub dolnej
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>
              Opis zabiegu wypełniania ust kwasem hialuronowym
            </Subtitle>
            <Paragraph>
              <b>Powiększenie ust kwasem hialuronowym</b> zawsze poprzedzone
              jest indywidualną konsultacją lekarską. Polega on na podaniu za
              pomocą cienkiej igły kwasu hialuronowego, który wypełni, uwydatni
              i wymodeluje usta nadając im równocześnie ładny kolor i wyrównując
              kontur. Podczas zabiegu możliwe jest również wypełnienie drobnych
              zmarszczek nad ustami czy subtelne uniesienie kącików ust.
            </Paragraph>
            <Paragraph>
              Ze względu na to, iż okolica ust jest wyjątkowo wrażliwa
              wypełnianie ust lub modelowania ust kwasem hialuronowym wykonuje
              się znieczulenie ust kremem znieczulającym, z wykorzystaniem
              preparatu zawierającego w składzie znieczulenie lub znieczulając
              usta stomatologicznie.
            </Paragraph>
            <Paragraph>
              Po zabiegu może wystąpić nieznacz ny obrzęk lub zasinienia w
              obrębie czerwieni wargowej, które mogą utrzymywać się maksymalnie
              do kilku dni. Najczęściej jednak nasi pacjenci cieszą się pięknymi
              ustami już na drugi dzień
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty powiększania ust?
            </Subtitle>
            <Paragraph>
              Efekty zabiegu powiększania lub modelowania ust kwasem
              hialuronowym widoczne są natychmiast, natomiast{' '}
              <a href="/leksykon/pelne-usta/" target="_blank">
                pełny efekt
              </a>{' '}
              pojawia się po kilku dniach po ustąpieniu ewentualnego obrzęku. W
              zależności od preparatu efekty mogą się utrzymywać od 6 do 12
              miesięcy, następnie preparat zostaje naturalnie zmetabolizowany
              przez organizm.
            </Paragraph>
            <Paragraph>
              Wchłaniający się kwas zapewnia doskonałe nawilżenie tkanek dając
              efekt jędrnych i pełnych ust o regularnym konturze. Dodatkowo daje
              on możliwość niewielkiej{' '}
              <a href="/leksykon/asymetria-ust/" target="_blank">
                korekty kształtu ust
              </a>
              . Zdjęcia ukazują efekt zabiegu u konkretnego pacjenta. Efekt
              zabiegu może się różnić w zależności od indywidualnych cech
              pacjenta, liczby powtórzeń zabiegu, stosowania się pacjenta do
              zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby
              przeprowadzającej zabieg.
            </Paragraph>
            <ImageWrapper>
              <Image
                src={images.first.childImageSharp}
                alt="modelowanie ust kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.second.childImageSharp}
                alt="powiększania ust kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.third.childImageSharp}
                alt="wypełnienie ust kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.fourth.childImageSharp}
                alt="powiększania ust ideale medycyna estetyczna warszawa wola"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena zabiegu powiększenia ust</Subtitle>
            <Paragraph>
              Cena zabiegu powiększania lub modelowania ust kwasem hialuronowym
              to 900 zł. Przy pierwszej wizycie odbierz swoją kartę
              lojalnościową i otrzymaj -30% na czwarty zabieg medycyny
              estetycznej wykonany w klinice IDEALE Medycyna Estetyczna,
              Warszawa, Wola.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do zabiegu powiększania ust</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>
                Miejscowy stan zapalny skóry (np. <b>aktywna opryszczka</b>)
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Czy zabieg powiększania ust boli?</Subtitle>
            <Paragraph>
              W gabinecie IDEALE często otrzymujemy pytania dotyczące komfortu
              przy <b>powiększaniu ust</b>. Odczuwanie bólu jest indywidualną
              sprawą i różni się w zależności od pacjenta. U kobiet, czynniki
              takie jak hormony i faza cyklu miesięcznego mogą wpływać na
              odczuwanie bólu. Ponadto, stan psychiczny oraz poziom lęku przed
              zabiegiem mają duże znaczenie. Biorąc pod uwagę wrażliwość{' '}
              <b>okolic ust</b>, zalecamy stosowanie miejscowego znieczulenia
              przy użyciu kremu, który nakładamy na skórę około 30-40 minut
              przed zabiegiem. Po tym czasie usta stają się mniej wrażliwe, co
              minimalizuje odczucie bólu. Część preparatów z kwasem hialuronowym
              zawiera również dodatek lidokainy, co dodatkowo podnosi komfort
              zabiegu. W przypadkach wyjątkowej wrażliwości pacjentki, zalecamy
              zastosowanie znieczulenia stomatologicznego, które całkowicie
              niweluje odczucie bólu.
            </Paragraph>
            <Subtitle>Czym jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrności,
              elastyczności i gładkości skóry. Wraz z upływem czasu, produkcja
              naturalnego kwasu hialuronowego w organizmie zmniejsza się, co
              może prowadzić do utraty nawilżenia i powstawania zmarszczek.
            </Paragraph>
            <Paragraph>
              W związku z tym, kwas hialuronowy jest szeroko wykorzystywany w
              medycynie estetycznej. Może być stosowany w postaci zastrzyków lub
              jako składnik kosmetyków, takich jak kremy, serum czy maski, w
              celu nawilżenia skóry, redukcji zmarszczek i poprawy ogólnego
              wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, <b>kwas hialuronowy</b> może być stosowany w medycynie,
              na przykład w leczeniu chorób stawów, takich jak osteoartroza,
              gdzie wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia
              bólu i poprawy funkcji stawu.
            </Paragraph>
            <Subtitle>Dlaczego warto modelować usta w IDEALE?</Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi,
              doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas
              piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na
              młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie
              na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd
              naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc
              parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer5;

export const Head = () => <SEO tags={TAGS.modelowaniePowiekszanieUst} />;
