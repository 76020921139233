import React from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { object, objectOf } from 'prop-types';

const SImage = styled(NonStretchedImage)`
  margin-bottom: 30px;
`;

export const Image = ({ src, alt }) => (
  <SImage childImageSharp={src} alt={alt} />
);

Image.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  src: objectOf(object).isRequired,
};
