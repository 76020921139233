export const OFFERS = [
  {
    id: 1,
    text: 'Usuwanie zmarszczek mimicznych BOT',
    href: 'usuwanie-zmarszczek-mimicznych-bot',
  },
  {
    id: 2,
    text: 'Molekuła Młodości H+L ',
    href: 'biostymulator-tkankowy-profhilo',
  },
  {
    id: 3,
    text: 'Wolumetria twarzy',
    href: 'wolumetria-twarzy',
  },
  {
    id: 4,
    text: 'Powiększanie lub modelowanie ust kwasem hialuronowym',
    href: 'modelowanie-powiekszanie-ust',
  },
  {
    id: 5,
    text: 'Wypełnianie zmarszczek kwasem hialuronowym',
    href: 'wypelnianie-zmarszczek-kwasem-hialuronowym',
  },
  {
    id: 6,
    text: 'Korekta uśmiechu dziąsłowego “gummy smile”',
    href: 'gummy-smile-usmiech-dziaslowy',
  },
  {
    id: 7,
    text: 'Leczenie bruksizmu',
    href: 'usuwanie-zmarszczek-mimicznych-bot',
  },
];
