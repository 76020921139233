import React from 'react';
import { PageHeader } from 'components/page-header';
import { array, node } from 'prop-types';

export const Header = ({ children, breadcrumbsLinks }) => (
  <PageHeader links={breadcrumbsLinks}>{children}</PageHeader>
);

Header.propTypes = {
  children: node.isRequired,
  breadcrumbsLinks: array.isRequired,
};
