import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SBackButton = styled(Link)`
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${COLORS.CREAMY};
  margin-top: 4.375rem;
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-left: 3px solid ${COLORS.CREAMY};
    border-bottom: 3px solid ${COLORS.CREAMY};
    transform: rotate(45deg);
    transition: 0.3s ease-out;
    display: inline-block;
    margin-right: 0.625rem;
  }
`;

export const BackButton = ({ to }) => <SBackButton to={to}>wróć</SBackButton>;

BackButton.propTypes = {
  to: string.isRequired,
};
