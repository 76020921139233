import React from 'react';
import styled from 'styled-components';
import { LinkButton } from 'components/link-button';
import { OfferTile } from 'components/offer-tile';
import { SectionTitle } from 'components/section-title';
import { array, string } from 'prop-types';
import MEDIA from 'styles/media';

const SSectionWrapper = styled.div`
  margin: 4rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SOffersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 330px);
  grid-template-rows: repeat(auto-fit, 330px);
  grid-gap: 30px;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: 3rem 0;
  ${MEDIA.max1024`
    grid-template-columns: repeat(auto-fit, 300px);
    grid-template-rows: repeat(auto-fit, 300px);
  `}
`;

const SButton = styled(LinkButton)`
  margin: 2rem auto;
  align-self: center;
`;

export const OtherTreatments = ({ offers, title }) => {
  const offersToRender = offers.map((offer) => (
    <OfferTile key={offer.id} offer={offer} />
  ));

  return (
    <SSectionWrapper>
      <SectionTitle title={title} />
      <SOffersWrapper>{offersToRender}</SOffersWrapper>
      <SButton text="zobacz pełną ofertę" to="/cennik" transparent />
    </SSectionWrapper>
  );
};

OtherTreatments.propTypes = {
  offers: array.isRequired,
  title: string.isRequired,
};
