import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { number, shape, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';
import { gaEvent } from 'utils/utils';

const SOfferTile = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 330px;
  position: relative;
  border: 2px solid ${COLORS.CREAMY};
  transition: 0.3s ease-out;
  box-shadow: ${STYLES_CONSTANTS.BOX_SHADOWS.BLACK_LIGHT};
  text-decoration: none;
  color: inherit;

  &:hover {
    .offer-link {
      transition: 0.3s ease-out;
      position: absolute;
      color: ${STYLES_CONSTANTS.COLORS.WHITE};
    }
    color: ${STYLES_CONSTANTS.COLORS.WHITE};
    background-color: ${STYLES_CONSTANTS.COLORS.CREAMY};
  }
  ${MEDIA.max1024`
    height: 300px;
    width: 300px;
    justify-self: center;
  `}
  ${MEDIA.max768`
    order: 2;
  `}
`;

const SText = styled.h3`
  text-align: center;
  font-size: 1.4rem;
  padding: 1.5rem;
  font-family: 'Abhaya Libre', serif;
`;

const SReadMore = styled.span`
  position: absolute;
  bottom: 25px;
  font-weight: bold;
  color: ${COLORS.CREAMY};
  text-transform: uppercase;
`;

export const OfferTile = (props) => {
  const {
    offer: { id, text, href },
    className,
  } = props;

  const handleClick = () => {
    gaEvent('procedure-check');
  };

  return (
    <SOfferTile
      key={id}
      to={`/oferta/${href}`}
      className={className}
      onClick={handleClick}
    >
      <SText>{text}</SText>
      <SReadMore className="offer-link">Zobacz</SReadMore>
    </SOfferTile>
  );
};

OfferTile.propTypes = {
  offer: shape({
    id: number,
    text: string,
  }).isRequired,
  className: string,
};
